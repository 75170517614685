$bnz-blue: #002F6B;
$bnz-gold: #faa61a;

$primary-color: $bnz-blue;
$body-bg: #f5f5f5;

$sidenav-bg-color: #000640;
$sidebar-gradient-start: #000640;
$sidebar-gradient-end: $bnz-blue;

$link-color: $primary-color;
$link-decoration: underline;
.navbar-brand, .sidenav-item {
  text-decoration: none;
}

.navbar > a.nav-item {
  color: $bnz-gold;
}

@import "./base.scss";