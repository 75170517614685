.business-panel {
  height: 66px;
}
.business-image {
  width: 3rem;
  height: 3rem;
  border: 2px solid white;
  margin-right: 5px;
  margin-left: -10px;
}
