
.switcher {
  :global(.list-group-item-action) {
    color: #444444;
  }
  :global(.list-group-item-action.active) {
    color: #ffffff;
  }
  .businessInner {
    display: flex;
    align-items: center;
    .businessImage {
      width: 3rem;
      height: 3rem;
      border: 2px solid white;
      margin-right: 5px;
    }
  }
}